.home {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.side__container {
  flex: 1;
}

.center__container {
  flex: 2.5;
}

.compressor {
  margin-top: 50px;
  padding-bottom: 50px;
  .slider__container {
    width: 70%;
    margin: 30px auto 0 auto;
  }

  .img__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;

    .wrapper {
      position: relative;
      margin: 10px;
      display: inline-block;

      img {
        width: 200px;
        height: 200px;
        border-radius: 20px;
        display: block;
      }

      .size {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 5px 10px;
        background-color: rgba(20, 20, 20, 0.5);
        color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        position: absolute;
        bottom: 0;
        left: 0; /* Start from the very left */
        right: 0; /* Extend to the very right */
        border-bottom-left-radius: 20px; /* Match the image's bottom-left radius */
        border-bottom-right-radius: 20px; /* Match the image's bottom-right radius */
        z-index: 10;
      }
    }
  }
}
